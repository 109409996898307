import React from 'react'

import { Tournament as ITournament, Page } from '../../lib/createPages'
import { Root, Header } from '../../components'
import { Tournament } from '../../components/admin'
import { WpContextProvider, TournamentsContextProvider } from '../../contexts'
import { getJsonFromParams } from '../../helpers'

interface AdminTemplateProps {
  pageContext: { tournament: ITournament; edges: { node: Page }[] }
  location: Location
}

const AdminTournamentTemplate: React.FC<AdminTemplateProps> = ({ pageContext, location }) => {
  const params = getJsonFromParams<{ turnering: string; grupp: string }>(location.search.substr(1))

  return (
    <WpContextProvider tournament={pageContext.tournament}>
      <Root pageContext={pageContext}>
        <TournamentsContextProvider tournamentid="1351">
          <Header pageContext={pageContext}></Header>
          <Tournament tournamentId={+(params.turnering || 0)} />
        </TournamentsContextProvider>
      </Root>
    </WpContextProvider>
  )
}

export default AdminTournamentTemplate
